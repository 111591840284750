import React from 'react';
import sanitizeHtml from 'sanitize-html';

import GreenStripe from '../../components/GreenStripe';
import Headline from '../../components/headline/Headline';
import { Image } from '../../components/Image';
import { Section } from '../../components/Section';
import { SEO } from '../../components/Seo';
import { Submenu } from '../../components/Submenu';
import { AllMarkdownRemarkQueryResult } from '../../interfaces/AllMarkdownRemark';
import { MarkdownFileNode } from '../../interfaces/MarkdownFileNode';
import { PageProps } from '../../interfaces/PageProps';
import { allMarkdownRemark } from '../../queries/AllMarkdownRemark';
import { Container, Description } from '../../styles/CommonStyledComponents';
import { decodeUri } from '../../utils/DecodeUri';
import { findMarkdownFile } from '../../utils/FindMarkdownFile';
import { getKindergartenByPathname } from '../../utils/GetKindergartenByPathname';
import withLocation from '../../utils/WithLocation';

const EnrollmentsAndFees = (props: PageProps) => {
  const data: AllMarkdownRemarkQueryResult<MarkdownFileNode> | undefined =
    allMarkdownRemark<AllMarkdownRemarkQueryResult<MarkdownFileNode>>();
  const markdownActivitiesFile = findMarkdownFile(data!.allMarkdownRemark, props.location.pathname, 'enrollments');
  const markdownFeesFile = findMarkdownFile(data!.allMarkdownRemark, props.location.pathname, 'fees');

  return (
    <>
      <SEO title={decodeUri(getKindergartenByPathname(props.location.pathname))} />
      <GreenStripe />
      <Submenu location={props.location} orderCollection={['Zapisy', 'Opłaty']}></Submenu>

      <Section id="Zapisy">
        <Headline arrowColor="arrowGreen" title="Zapisy"></Headline>
        <Container>
          <Image fluid={markdownActivitiesFile?.frontmatter?.image?.childImageSharp.fluid} />
          <Description
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(markdownActivitiesFile?.html || ''),
            }}
          ></Description>
        </Container>
      </Section>
      <Section id="Opłaty">
        <Headline arrowColor="arrowGreen" title="Opłaty"></Headline>
        <Container>
          <Image fluid={markdownFeesFile?.frontmatter?.image?.childImageSharp.fluid} />
          <Description
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(markdownFeesFile?.html || ''),
            }}
          ></Description>
        </Container>
      </Section>
    </>
  );
};

export default withLocation(EnrollmentsAndFees);
